var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.disabled,"hover":""}},[_c('v-card-title',[_c('div',{staticClass:"text-h6 font-weight-light secondary--text text--lighten-2 mr-1"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.showQuantity && _vm.quantity > 0)?_c('span',{staticClass:"caption"},[_vm._v(" ("+_vm._s(_vm.quantity)+") ")]):_vm._e()]),_c('div',{staticClass:"text-h6 font-weight-bold"},[_c('currency',{attrs:{"value":_vm.total}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",class:{ 'd-none': !(_vm.filter || hover) },attrs:{"icon":"","small":"","color":_vm.filter ? 'primary' : null},on:{"click":function($event){return _vm.$emit('update:filter', !_vm.filter)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.filter ? "mdi-filter" : "mdi-filter-outline"))])],1)]}}],null,true)},[(!_vm.filter)?_c('span',[_vm._v("Filtrar")]):_c('span',[_vm._v("Remover filtro")])])],1),_c('v-card-text',[_c('v-sheet',{attrs:{"elevation":"6","color":_vm.backgroundColor}},[_c('v-sparkline',{attrs:{"smooth":"","auto-draw":"","line-width":"2","padding":"16","color":_vm.color,"auto-draw-duration":500,"value":_vm.chartData}})],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-divider',{staticClass:"my-2"}),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-clock ")]),_c('span',{staticClass:"text-caption grey--text font-weight-light"},[_vm._v(" "+_vm._s(_vm.footerLabel)+" "),(_vm.footer)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$moment(_vm.footer).fromNow())+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.footer,"LLL")))])])]:_c('span',[_vm._v("--")])],2)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }