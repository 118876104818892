<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <div class="d-flex flex-column flex-sm-row mt-n3 pt-3 pb-sm-3 toolbar">
      <v-btn
        v-show="$vuetify.breakpoint.xsOnly || !hasSelectedItem"
        color="success"
        class="mr-lg-auto mr-sm-4"
        to="/notas-fiscais/consumidor/nova"
        :large="!$vuetify.breakpoint.xsOnly"
        :fab="$vuetify.breakpoint.xsOnly"
        :fixed="$vuetify.breakpoint.xsOnly"
        :bottom="$vuetify.breakpoint.xsOnly"
        :right="$vuetify.breakpoint.xsOnly"
      >
        <v-icon :left="!$vuetify.breakpoint.xsOnly">mdi-plus-thick</v-icon>
        <span class="d-none d-sm-flex">Nova NFC-e</span>
      </v-btn>

      <v-btn
        v-show="hasSelectedItem"
        large
        text
        outlined
        class="mb-4 mr-lg-auto mr-sm-4"
        :loading="exporting"
        :block="$vuetify.breakpoint.xsOnly"
        @click="bulkDownload"
      >
        <v-icon left color="primary">mdi-file-download-outline</v-icon>
        Exportar
      </v-btn>

      <month-picker-field class="mr-sm-4 mb-4" v-model="monthFilter" />

      <div class="mb-4 flex-grow-1 flex-lg-grow-0">
        <search-field
          clearable
          label="Cliente ou Nota Fiscal"
          v-model="searchFilter"
        />
      </div>
    </div>

    <v-expand-transition>
      <v-row v-if="$vuetify.breakpoint.mdAndUp && !hasSelectedItem">
        <v-col cols="4">
          <nota-fiscal-list-chart
            color="green"
            title="Emitidas"
            footer-label="Última emissão"
            :footer="lastItemAuthorized"
            :loading="loadingChart"
            :disabled="loading"
            :month="monthFilter"
            :filter.sync="authorizedFilter"
            :value="itemsAuthorized"
          />
        </v-col>

        <v-col cols="4">
          <nota-fiscal-list-chart
            color="brown"
            title="Canceladas"
            footer-label="Último cancelamento"
            :footer="lastItemCanceled"
            :loading="loadingChart"
            :disabled="loading"
            :month="monthFilter"
            :filter.sync="canceledFilter"
            :value="itemsCanceled"
          />
        </v-col>

        <v-col cols="4">
          <nota-fiscal-list-chart
            color="orange"
            title="Pendentes"
            footer-label="Última alteração"
            :footer="lastItemPendingUpdatedAt"
            :loading="loadingChart"
            :disabled="loading"
            :month="monthFilter"
            :filter.sync="pendingFilter"
            :value="itemsPending"
          />
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-row>
      <v-col cols="12">
        <nota-fiscal-table
          recipient-header="Cliente"
          :loading="loading"
          :search="searchFilter"
          :items="itemsFiltered"
          v-model="selectedItems"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DownloadFile from "@/mixins/DownloadFile";
import MonthPickerField from "@/components/MonthPickerField";
import SearchField from "@/components/SearchField";
import NotaFiscalListChart from "@/components/NotaFiscal/ListChart";
import NotaFiscalTable from "@/components/NotaFiscal/Table";

export default {
  name: "NotasFiscaisCustomerList",
  metaInfo() {
    return {
      title: "Nota Fiscal de Consumidor",
    };
  },
  mixins: [DownloadFile],
  components: {
    MonthPickerField,
    SearchField,
    NotaFiscalListChart,
    NotaFiscalTable,
  },
  data: () => ({
    exporting: false,
    loadingChart: false,
    monthFilter: new Date().toISOString().substr(0, 7),
    searchFilter: "",
    operationFilter: [],
    pendingFilter: false,
    authorizedFilter: false,
    canceledFilter: false,
    selectedItems: [],
  }),
  computed: {
    itemsFiltered() {
      return this.itemsFilteredByStatus;
    },
    itemsFilteredByStatus() {
      let items = this.items;
      if (
        !(this.pendingFilter || this.authorizedFilter || this.canceledFilter)
      ) {
        return items;
      }
      return items.filter(({ status }) => {
        if (this.pendingFilter && status === "pending") {
          return true;
        }
        if (this.authorizedFilter && status === "authorized") {
          return true;
        }
        if (this.canceledFilter && status === "canceled") {
          return true;
        }
        return false;
      });
    },
    itemsPending() {
      return this.items.filter(({ status }) => status === "pending");
    },
    lastItemPendingUpdatedAt() {
      return this.itemsPending.reduce((res, { updatedAt }) => {
        updatedAt = this.$moment(updatedAt);
        if (!res || updatedAt.isAfter(res)) {
          return updatedAt.toDate();
        }
        return res;
      }, null);
    },
    itemsAuthorized() {
      return this.items.filter(({ status }) => status === "authorized");
    },
    lastItemAuthorized() {
      return this.itemsAuthorized.reduce((res, { authorized }) => {
        const authorizedAt = this.$moment(authorized.authorized_at);
        if (!res || authorizedAt.isAfter(res)) {
          return authorizedAt.toDate();
        }
        return res;
      }, null);
    },
    itemsCanceled() {
      return this.items.filter(({ status }) => status === "canceled");
    },
    lastItemCanceled() {
      return this.itemsCanceled.reduce((res, { canceled }) => {
        const canceledAt = this.$moment(canceled.canceled_at);
        if (!res || canceledAt.isAfter(res)) {
          return canceledAt.toDate();
        }
        return res;
      }, null);
    },
    items() {
      return this.nfces.map((item) => ({
        id: item.id,
        serie: item.serie,
        number: item.number,
        issueDate: item.issue_date,
        operationSlug: item.operation.slug,
        operationDescription: item.operation.description,
        recipient: item.recipient,
        total: item.totals.total,
        status: item.status,
        sefazId: item.sefaz_id,
        authorized: item.authorized,
        canceled: item.canceled,
        updatedAt: item.updated_at,
        viewHref: {
          name: "nf-customer-view",
          params: { id: item.id },
        },
        xmlUrl: item.authorized
          ? `https://files.giox.com.br/nfe/${item.sefaz_id}.xml`
          : "",
        pdfUrl: item.authorized
          ? `https://files.giox.com.br/nfe/${item.sefaz_id}.pdf`
          : "",
      }));
    },
    hasSelectedItem() {
      return this.selectedItems.length > 0;
    },
    hasOperation() {
      return this.operations.length > 0;
    },
    ...mapState("auth", ["company"]),
    ...mapState("notafiscal", ["loading"]),
    ...mapGetters("notafiscal", ["nfces"]),
  },
  watch: {
    company() {
      this.$nextTick().then(() => {
        // when a different company is selected, reset monthFilter
        this.monthFilter = new Date().toISOString().substr(0, 7);
        this.loadNFs();
      });
    },
    monthFilter() {
      this.loadNFs();
    },
  },
  mounted() {
    this.loadNFs();
  },
  methods: {
    async loadNFs() {
      this.selectedItems = [];
      this.$store.dispatch("notafiscal/fetch", {
        model: "nfce",
        month: this.monthFilter,
      });

      this.$nextTick().then(() => {
        this.updateCharts();
      });
    },
    updateCharts() {
      setTimeout(() => {
        this.loadingChart = true;
        setTimeout(() => {
          this.chartItems = this.itemsFiltered;
          this.$nextTick().then(() => {
            this.loadingChart = false;
          });
        }, 300);
      }, 100);
    },
    async bulkDownload() {
      if (!this.selectedItems.length) return;

      this.exporting = true;
      const pendings = this.selectedItems.filter(
        (item) => item.status === "pending"
      );
      if (pendings.length > 0) {
        this.notifyError("Somente NFC-e autorizada pode ser exportada");
        this.exporting = false;
        return;
      }

      try {
        const { data } = await this.$http.post("/v1/nfes/download", {
          types: ["xml", "pdf"],
          nfes: this.selectedItems.map((item) => ({
            sefaz_id: item.sefazId,
          })),
        });
        const url = `/v1/nfes/download/${data.id}`;
        const filename = `${this.company.cnpj}-nfces.zip`;
        await this.downloadFileWhenReady(url, filename, data.id);
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao exportar as NFC-es 😢");
      }
      this.exporting = false;
    },
  },
};
</script>

<style lang="sass">
.v-card--disabled
  > *:not(.v-card__progress)
    opacity: 0.4
</style>
