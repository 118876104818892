<template>
  <v-hover v-slot="{ hover }">
    <v-card :loading="loading" :disabled="disabled" hover>
      <v-card-title>
        <div
          class="text-h6 font-weight-light secondary--text text--lighten-2 mr-1"
        >
          {{ title }}
          <span v-if="showQuantity && quantity > 0" class="caption">
            ({{ quantity }})
          </span>
        </div>
        <div class="text-h6 font-weight-bold">
          <currency :value="total" />
        </div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              class="ml-auto"
              :class="{ 'd-none': !(filter || hover) }"
              :color="filter ? 'primary' : null"
              @click="$emit('update:filter', !filter)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{
                filter ? "mdi-filter" : "mdi-filter-outline"
              }}</v-icon>
            </v-btn>
          </template>

          <span v-if="!filter">Filtrar</span>
          <span v-else>Remover filtro</span>
        </v-tooltip>
      </v-card-title>

      <v-card-text>
        <v-sheet elevation="6" :color="backgroundColor">
          <v-sparkline
            smooth
            auto-draw
            line-width="2"
            padding="16"
            :color="color"
            :auto-draw-duration="500"
            :value="chartData"
          />
        </v-sheet>
      </v-card-text>

      <v-card-text class="pt-0">
        <v-divider class="my-2" />

        <v-icon class="mr-2" small>
          mdi-clock
        </v-icon>
        <span class="text-caption grey--text font-weight-light">
          {{ footerLabel }}

          <template v-if="footer">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ $moment(footer).fromNow() }}
                </span>
              </template>

              <span>{{ footer | moment("LLL") }}</span>
            </v-tooltip>
          </template>
          <span v-else>--</span>
        </span>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import Decimal from "decimal.js";
import Currency from "@/components/Currency";

export default {
  name: "NotaFiscalListChart",
  components: {
    Currency,
  },
  props: {
    loading: Boolean,
    disabled: Boolean,
    title: String,
    showQuantity: {
      type: Boolean,
      default: true,
    },
    footer: Date,
    footerLabel: String,
    color: String,
    value: Array,
    month: String, // YYYY-MM
    filter: Boolean,
  },
  computed: {
    backgroundColor() {
      return [this.color, "lighten-5"].join(" ");
    },
    items() {
      return this.value || [];
    },
    chartData() {
      const startDate = this.$moment(this.month);
      const endDate = startDate
        .clone()
        .add(1, "months")
        .subtract(1, "seconds");

      let totalPerDay = {};
      this.items.forEach((item) => {
        const date = this.$moment(item.issueDate).format("YYYY-MM-DD"),
          current = totalPerDay[date] || new Decimal(0);
        totalPerDay[date] = current.add(item.total);
      });

      let items = [],
        total = new Decimal(0);

      for (; endDate.isAfter(startDate); startDate.add(1, "day")) {
        const date = startDate.format("YYYY-MM-DD"),
          current = totalPerDay[date];
        if (current) {
          total = total.add(current);
        }
        items.push(total);
      }
      return items.map((item) => item.toNumber());
    },
    quantity() {
      return this.items.length;
    },
    total() {
      return this.items.reduce((total, item) => {
        return total.add(item.total);
      }, new Decimal(0));
    },
  },
};
</script>
